<template>
  <div class="report" id="report">
    <Header />
    <div class="other__narrow__banner">
      <img :src="'https://www.elevator.com.vn/upload/'+ report.image" alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4>{{report.title}}</h4>
        </div>
      </div>
    </div>
    <div class="other__ck__block mt-3">
      <div class="container" v-html="itemOne">
      </div>
    </div>
    <div class="other__ck__block">
      <div class="container">
        <div class="other__ck__title title__bar">
          <h2>檢舉通報</h2>
        </div>
        <div class="other__forms">
          <form class="needs-validation" novalidate>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label for="validationCustom01">你的姓名</label>
                <input
                v-model="name"
                  type="text"
                  class="form-control"
                  id="validationCustom01"
                  placeholder="請輸入姓名"
                  value="name"
                  required
                />
                <div class="invalid-feedback">
                  請輸入姓名
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="validationCustom02">服務公司</label>
                <input
                  v-model="company"
                  type="text"
                  class="form-control"
                  id="validationCustom02"
                  placeholder="請輸入公司"
                  value="company"
                />
                <div class="invalid-feedback">
                  請輸入公司
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label for="validationCustom01">聯絡電話</label>
                <input
                  v-model="phone"
                  type="number"
                  class="form-control"
                  id="validationCustom01"
                  placeholder="請輸入電話"
                  value="phone"
                />
                <div class="invalid-feedback">
                  請輸入電話
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="validationCustom02">E-Mail</label>
                <input
                v-model="email"
                  type="email"
                  class="form-control"
                  id="validationCustom02"
                  placeholder="請輸入E-mail"
                  value="email"
                  required
                />
                <div class="invalid-feedback">
                  請輸入email
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label for="validationCustom01">聯絡傳真</label>
                <input
                v-model="fax"
                  type="text"
                  class="form-control"
                  id="validationCustom01"
                  placeholder="請輸入傳真"
                  value="fax"
                />
                <div class="invalid-feedback">
                  請輸入傳真
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="validationCustom01">訊息內容</label>
              <textarea
              v-model="content"
                class="form-control"
                id="validationTextarea"
                placeholder="訊息內容"
                required
              ></textarea>
              <div class="invalid-feedback">
                請輸入訊息內容
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input
                v-model="check"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="invalidCheck2"
                  required
                />
                <label class="form-check-label" for="invalidCheck2" style="color:black;">
                  本人已閱讀並同意本公司
                </label>
                <a class="other__ck__text__green"
                  data-toggle="modal" 
                  data-target="#exampleModal">
                  個人資料蒐集政策
                </a>
                <div class="invalid-feedback">
                  尚未同意個人資料蒐集政策
                </div>
              </div>
            </div>
            <div class="modal fade" id="exampleModal"
                  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">個人資料收集政策</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                <div class="modal-body" v-html="itemTwo">
                </div>
              </div>
              </div>
            </div>
            <button class="btn btn_green btn_submit" type="submit" @click.prevent.stop="SubmitData" :disabled ="disable">
              確認送出
            </button>
          </form>
        </div>
      </div>
    </div>
    <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" :loadRecaptchaScript="true" sitekey="6Ld_ohoeAAAAAHqE5M_OY3nX2uwAY6ki5LeYvSJv"
    size="invisible"/>
  </div>
</template>

<script>
import { apiReport } from "@/api/pages.js";
import { VueRecaptcha } from 'vue-recaptcha';
import { Toast } from "./../utils/helpers"
import formPostAPI from "./../api/formPost"
import Header from "./../components/Header";
export default {
  name: "Report",
  components: {
    Header,
    VueRecaptcha
  },
  data(){
    return{
      item: {},
      report:{},
      itemOne:{},
      itemTwo:{},
      name: '',
      company: '',
      phone: '',
      email: '',
      fax: '',
      content: '',
      check: false,
      token: '',
      disable: false
    }
  },
  created(){
    this.getData()
    this.getHashValue()
  },
  mounted() {
    document.querySelector('body').scrollIntoView({behavior: "smooth"})
    setTimeout( () => {
      const target = $(`${this.hash}`).offset().top
      $(window).scrollTop(target)
    }, 100)
  },
  methods: {
    async postDataHandler () {
      const form = document.querySelector('.needs-validation')
      console.log('通過checkValidity')
      const data = `{
        "name": "${this.name}",
        "company": "${this.company}",
        "phone": "${this.phone}",
        "email": "${this.email}",
        "fax": "${this.fax}",
        "content": "${this.content}",
        "token": "${this.token}"
      }`
      
      this.disable = true
      try {
        const data_JSON = JSON.parse(data)
        console.log(data_JSON)
        const response = await formPostAPI.SubmitReportPost({ data_JSON })
        console.log(response)
        if(response.data.result === 'OK'){
          Toast.fire({
            icon: 'success',
            title: 'Dữ liệu đã được gửi！'
          })
          this.name = ''
          this.company = ''
          this.phone = ''
          this.email = ''
          this.fax = ''
          this.content = ''
          this.check = false
          form.classList.remove('was-validated')
        }
      }
      catch {
        Toast.fire({
          icon: 'warning',
          title: 'Truyền dữ liệu không thành công, vui lòng đợi!'
        })
        console.log('error')
      }
      this.disable = false
    },
    async getData(){
      const res = await apiReport();
      this.item = res.data;
      const {report,item,item1} = res.data
      this.report = {
        ...report
      }
      this.itemOne = item
      this.itemTwo = item1
    },
    getHashValue(){
      if(this.$route.hash){
        this.hash = this.$route.hash
      }else{
        this.hash = `#${this.$route.name}`
      }
    },
    async SubmitData () {
      const form = document.querySelector('.needs-validation')
      form.classList.add('was-validated')
      if(!form.checkValidity()) return
      console.log('通過checkValidity')
      this.$refs.recaptcha.execute();
    },
    onVerify: function (response) {
      console.log('Verify: ' + response)
      this.token = response
      this.postDataHandler()
    },
    onExpired: function () {
      console.log('Expired')
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
  },
};
</script>

<style lang="css">
    .modal-dialog{
      max-height: 80%;
      max-width: 90%;
      margin: auto;
      margin-top: 45px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }
    @media screen and (min-width: 768px){
      .modal-dialog {
        max-width: 70%;
        max-height: 500px;
      }  
    }
    @media screen and (min-width: 1200px){
      .modal-dialog {
        margin-top: 20vh; 
      }  
    }
</style>
