<template>
  <div>
    <!-- 手機 -->
    <div class="navbar">
      <div class="container">
        <div class="navbar__logo">
          <router-link :to="{ name: 'Home' }"
            ><img src="../assets/pic/logo.png"
          /></router-link>
        </div>
        <nav role="navigation">
          <div id="menuToggle">
            <input type="checkbox" />
            <span class="menu-btn"></span>
            <span class="menu-btn"></span>
            <span class="menu-btn"></span>
            <ul id="menu">
              <router-link :to="{ name: 'Home' }" class="mobile_link">
                <li class="has-submenu">
                  Trang đầu
                  <span class="menuToggle__line"></span>
                </li>
              </router-link>
              <router-link :to="{name:'About'}" class="mobile_link">
                <li class="has-submenu">
                  Giới Thiệu
                  <div class="mobile_menu_icon" @click.prevent.stop="about = !about"></div>
                  <span class="menuToggle__line"></span>
                  <ul class="submenu__list">
                    <li>
                      <router-link class="mobile_link" :to="{ name: 'Intro' }"
                    v-scroll-to="'#Intro'"
                      >
                        Giới Thiệu
                      </router-link>
                    </li>
                    <li>
                      <router-link class="mobile_link" :to="{ name: 'AboutHistory' }"
                      v-scroll-to="'#AboutHistory'"
                      >
                        Quá Trình Phát Triển
                      </router-link>
                    </li>
                    <li>
                      <router-link class="mobile_link" :to="{ name: 'AboutGlory' }"
                      v-scroll-to="'#AboutGlory'"
                      >
                        Vinh Dự
                      </router-link>
                    </li>
                  </ul>
                </li>
              </router-link>
              <router-link :to="{ name: 'product', params: {ptid: 174, psid: 177}}" @click.prevent.stop="productClick()" class="mobile_link">
                <li class="has-submenu">
                  Sản Phẩm
                  <div class="mobile_menu_icon" @click.prevent.stop="product = !product"></div>
                  <span class="menuToggle__line"></span>
                  <ul class="submenu__list">
                    <li v-for="a in ProductMenu.prodType" :key="a.id">
                      <a v-if ="a.id !== 176" class="levelTwoButton" href="#" @click.prevent.stop="productFreight = !productFreight">
                        {{a.title}}
                        <!-- <span v-html="a.title"></span> -->
                      </a>
                      <router-link v-else :to="{ name: 'product', params: {ptid: 176, psid: 275}}">
                        {{a.title}}
                      </router-link>
                      <ul id="product_levelThree">
                        <li  v-for="b in ProductMenu.prodSerial.filter(x => x.parent_id == a.id)"  :key="b.id">
                          <router-link v-if="b.parent_id !== 176" :to="{ name: 'product', params: { ptid: a.id, psid: b.id}}" @click="checkReload()" >
                              {{b.title}}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </router-link >
              <router-link :to="{name:'Professional'}" class="mobile_link">
                <li class="has-submenu">
                  Sản Xuất
                  <div class="mobile_menu_icon" @click.prevent.stop="professional = !professional"></div>
                  <span class="menuToggle__line"></span>
                  <ul class="submenu__list">
                    <li>
                      <router-link :to="{ name: 'Process' }" class="mobile_link" v-scroll-to="'#Process'">
                        Quy Trình Làm Việc
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'Promise' }" class="mobile_link" v-scroll-to="'#Promise'">
                        Chất Lượng
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'Quality' }" class="mobile_link" v-scroll-to="'#Quality'">
                        Chính sách chất lượng
                      </router-link>
                    </li>
                  </ul>
                </li>
              </router-link>
              <router-link :to="{name: 'Service'}" class="mobile_link">
                <li class="has-submenu">
                  Dịch Vụ
                  <div class="mobile_menu_icon" @click.prevent.stop="service = !service"></div>
                  <span class="menuToggle__line"></span>
                  <ul class="submenu__list">
                    <li>
                      <router-link :to="{ name: 'ServiceCenter' }" v-scroll-to="'#ServiceCenter'" class="mobile_link">
                        Dịch Vụ Của Chúng Tôi
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'ServiceLocation' }"
                      v-scroll-to="'#ServiceLocation'" class="mobile_link"
                      >
                        Địa Điểm
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'ServiceRemoteControl' }" 
                      v-scroll-to="'#ServiceRemoteControl'" class="mobile_link"
                      >
                        Tương Tác Từ Xa & Hệ Thống Giám Sát
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'ServiceExample' }"
                      v-scroll-to="'#ServiceExample'" class="mobile_link"
                      >
                        Khách Hàng
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'ServiceProductReponsibility' }"
                      v-scroll-to="'#ServiceProductReponsibility'" class="mobile_link"
                      >
                        Trách Nhiệm Pháp Lý
                      </router-link>
                    </li>
                  </ul>
                </li>
              </router-link>
              <router-link :to="{ name: 'Contact' }" class="mobile_link">
                <li class="has-submenu">
                  Liên Hệ
                  <span class="menuToggle__line"></span>
                </li>
              </router-link>
              <router-link :to="{ name:'Resource'}" class="mobile_link">
                <li class="has-submenu">
                  Nguồn Nhân lực
                  <div class="mobile_menu_icon" @click.prevent.stop="resource = !resource"></div>
                  <span class="menuToggle__line"></span>
                  <ul class="submenu__list">
                    <li>
                      <a href="https://www.104.com.tw/jobs/main/" class="mobile_link" target="_blank" @click="handleAnchor">
                        Tuyển Dụng
                      </a>
                    </li>
                    <li>
                      <router-link :to="{ name: 'ResourceHumanResources' }" class="mobile_link"
                      v-scroll-to="'#ResourceHumanResources'"
                      >
                      Công Việc
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'ResourceContactUs' }" class="mobile_link"
                      v-scroll-to="'#ResourceContactUs'"
                      >
                        Địa Điểm Văn Phòng
                      </router-link>
                    </li>
                  </ul>
                </li>
              </router-link>
              <!-- <router-link :to="{name:'Invest', params:{l1:41,l2:160}}" class="mobile_link">
                <li class="has-submenu">
                  Nhà đầu tư
                  <div class="mobile_menu_icon" @click.prevent.stop="investFinance = !investFinance"></div>
                  <span class="menuToggle__line"></span>
                  <ul class="submenu__list">
                    <li  v-for="a in InvestMenu.investLevel1" :key="a.id">
                      <a class="levelTwoButton" @click.prevent.stop="investFinance = !investContact"
                      >{{a.title}}
                      </a>
                      <ul id="invest_levelThree">
                        <li  v-for="b in InvestMenu.investLevel2.filter(x => x.parent_id == a.id)" :key="b.id">
                        <router-link  :to="{ name: 'Invest', params: { l1: a.id, l2: b.id} }"  @click="checkReload()">
                          {{b.title}}
                        </router-link>
                        </li>
                      </ul>
                    </li>

                  </ul>
                </li>
              </router-link> -->
              <!-- <router-link :to="{ name: 'Report' }" class="mobile_link">
                <li class="has-submenu">
                  檢舉專區
                  <span class="menuToggle__line"></span>
                </li>
              </router-link> -->
              <!-- <a href="https://erikterwan.com/" target="_blank"> -->
              <a href="#">
                <li class="has-submenu">
                  Ngôn ngữ
                  <div class="mobile_menu_icon" @click.prevent.stop="lan = !lan">
                  </div>
                  <span class="menuToggle__line"></span>
                  <ul class="submenu__list" v-show="lan">
                    <li><a href="https://www.volbin.com.tw">繁體中文</a></li>
                    <li><a href="https://www.volbin.com.cn">简体中文</a></li>
                    <li><a href="https://www.volbin.com/en">English</a></li>
                    <li><a href="https://www.volbin.com.vn">Vietnamese</a></li>
                  </ul>
                </li>
              </a>
            </ul>
          </div>
        </nav>
        <!-- 桌機語系 -->
        <div class="lan_wrapper">
          <span class="lan_icon" href="#">
            <img src="../assets/pic/language.png" />
          </span>
          <label for="lan"></label>
          <select name="lan" v-model="selecedtLan" @change="changeLanHandler">
            <option value="zh">繁體中文</option>
            <option value="ch">简体中文</option>
            <option value="en">English</option>
            <option value="vn">Vietnamese</option>
          </select>
        </div>
      </div>
    </div>
    <!-- 桌機 -->
    <div class="navbar__menu__list">
      <div class="container">
        <ul id="menu">
          <li class="pc__menu__dropdown">
            <router-link :to="{name: 'About'}">Giới Thiệu</router-link>
            <div class="pc__menu__dropdown__content">
              <div class="container">
                <ul>
                  <h2>
                    Giới Thiệu
                  </h2>
                  <li style="margin-right:20px;">
                    <router-link :to="{ name: 'Intro' }"
                    v-scroll-to="'#Intro'"
                    >
                      Giới Thiệu
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'AboutHistory'}" v-scroll-to="'#AboutHistory'"
                      >Quá Trình Phát Triển
                      </router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'AboutGlory' }" 
                    v-scroll-to="'#AboutGlory'">
                      Vinh Dự
                    </router-link>
                  </li>
                </ul>
                <div class="dropdown__pic">
                    <img src="../assets/pic/tower.png" />
                  <span>
                    Chuyên nghiệp, Dịch vụ, Chính trực, cộng hưởng, Bền vững là triết lý kinh doanh. Tạo ra các công cụ vận chuyển ngay thẳng an toàn , thoải mái hơn và bền hơn
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li class="pc__menu__dropdown">
            <router-link :to="{ name: 'product', params: {ptid: 174, psid: 177}}">Sản Phẩm</router-link>
            <div class="pc__menu__dropdown__content collapse navbar-collapse"
                  id="navbarSupportedContent">
              <div class="container">
                <ul>
                  <h2>Sản Phẩm</h2>
                  <li class="clickable product_first_level" v-for="a in ProductMenu.prodType" :key="a.id" style="width:310px;">
                    <router-link v-if="a.id !== 176" :to="{ name: 'product'}" @click.prevent.stop="productClick()">
                      <span v-html="a.title"></span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style=";transform: ;msFilter:;"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg>
                    </router-link>
                    <router-link v-else :to="{name: 'product',params: { ptid: 176, psid: 275}}">
                      <span v-html="a.title"></span>
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style=";transform: ;msFilter:;"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg> -->
                    </router-link>
                    <ul class="dropdown-menu-second" style="padding-left:150px;">
                      <li class="dropdown-menu-second-list"  v-for="b in ProductMenu.prodSerial.filter(x => x.parent_id == a.id)" :key="b.id">
                        
                        <router-link v-if="b.parent_id !== 176" :to="{ name: 'product', params: { ptid: a.id, psid: b.id}}"  @click="checkReload()">
                            <span v-html="b.title"></span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style=";transform: ;msFilter:;"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg>
                        </router-link>
                        <ul class="dropdown-menu-third">
                          <li v-for="c in ProductMenu.productData.filter(x => x.parent_id == b.id)" :key="c.id">
                            <router-link :to="{ name: 'productitem', params: { ptid: a.id, psid: b.id, pdid:c.id}}" :key="c.id"  @click="checkReload()" style="width:300px;">
                                <span v-html="c.title"></span>
                            </router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="pc__menu__dropdown">
            <router-link :to="{ name: 'Professional' }">Sản Xuất</router-link>
            <div class="pc__menu__dropdown__content">
              <div class="container">
                <ul>
                  <h2>
                    Sản Xuất
                  </h2>
                  <li style="margin-right:20px;">
                    <router-link :to="{ name: 'Process' }"
                    v-scroll-to="'#Process'"
                      >Quy Trình Làm Việc</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Promise' }"
                    v-scroll-to="'#Promise'"
                      >Chất Lượng</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Quality' }"
                    v-scroll-to="'#Quality'"
                      >Chính sách chất lượng</router-link
                    >
                  </li>
                </ul>
                <div class="dropdown__pic">
                  <img src="../assets/pic/menu_professional_banner.png" />
                  <span
                    >phương pháp độc đáo được thiết kế riêng theo nhu cầu doanh nghiệp
                    </span
                  >
                </div>
              </div>
            </div>
          </li>
          <li class="pc__menu__dropdown">
            <router-link :to="{name:'Service'}">Dịch Vụ</router-link>
            <div class="pc__menu__dropdown__content">
              <div class="container">
                <ul>
                  <h2>
                    Dịch Vụ
                  </h2>
                  <li style="margin-right:20px;">
                    <router-link :to="{ name: 'ServiceCenter' }" 
                    v-scroll-to="'#ServiceCenter'"
                      >Dịch Vụ Của Chúng Tôi</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'ServiceLocation' }" 
                    v-scroll-to="'#ServiceLocation'"
                      >Địa Điểm</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'ServiceRemoteControl' }" 
                    v-scroll-to="'#ServiceRemoteControl'"
                      >Tương Tác Từ Xa & Hệ Thống Giám Sát</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'ServiceExample' }"
                    v-scroll-to="'#ServiceExample'"
                      >Khách Hàng</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'ServiceProductReponsibility' }" 
                    v-scroll-to="'#ServiceProductReponsibility'"
                      >Trách Nhiệm Pháp Lý</router-link
                    >
                  </li>
                </ul>
                <div class="dropdown__pic">
                  <img src="../assets/pic/menu_service_banner.png" />
                  <span
                    >Với các dịch vụ chuyên nghiệp và trang thiết bị mới nhất, Hongwei Industrial có thể giúp các công ty hóa chất giảm chi phí, tăng Lực lượng sản xuất và đạt được các mục tiêu bảo vệ môi trường.
                    </span
                  >
                </div>
              </div>
            </div>
          </li>
          <li class="pc__menu__dropdown">
            <router-link :to="{ name: 'Contact' }">Liên Hệ</router-link>
          </li>
          <li class="pc__menu__dropdown">
            <router-link :to="{name:'Resource'}">Nguồn Nhân lực</router-link>
            <div class="pc__menu__dropdown__content">
              <div class="container">
                <ul>
                  <h2>
                    Nguồn Nhân lực
                  </h2>
                  <li style="margin-right:20px;">
                    <a href="https://www.104.com.tw/jobs/main/" target="_blank">
                      Tuyển Dụng
                    </a>
                  </li>
                  <li>
                    <router-link :to="{ name: 'ResourceHumanResources' }"
                    v-scroll-to="'#ResourceHumanResources'"
                      >Công Việc</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'ResourceContactUs' }"
                    v-scroll-to="'#ResourceContactUs'"
                      >Địa Điểm Văn Phòng</router-link
                    >
                  </li>
                </ul>
                <div class="dropdown__pic">
                  <img src="../assets/pic/menu_resource_banner.png" />
                  <span
                    >Cung cấp danh mục sản phẩm và dịch vụ toàn diện. hoan nghênh để lại thông tin. Chúng tôi sẽ sắp xếp người liên hệ với bạn trong thời gian sớm nhất!
                    </span
                  >
                </div>
              </div>
            </div>
          </li>
          <!-- <li class="pc__menu__dropdown">
            <router-link :to="{name:'Invest', params:{l1:41,l2:160}}">Nhà đầu tư</router-link>
            <div class="pc__menu__dropdown__content">
              <div class="container">
                <ul>
                  <h2>Nhà đầu tư</h2>
                  <li class="invest__dropdown__menu product_first_level" v-for="a in InvestMenu.investLevel1" :key="a.id">
                    <a href="#" @click.prevent.stop="handleToddleInvest">
                      
                      <span v-html="a.title"></span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style=";transform: ;msFilter:;"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg>
                    </a>
                    <ul class="dropdown-menu-second">
                      <li  v-for="b in InvestMenu.investLevel2.filter(x => x.parent_id == a.id)" :key="b.id">
                          <router-link :to="{ name: 'Invest', params: { l1: a.id, l2: b.id}}"   @click="checkReload()"
                          v-html="b.title"></router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="dropdown__pic dropdown_pic_invest_pic" id="invest__dropdown__pic">
                  <img src="../assets/pic/menu_invest_banner.png" />
                  <span
                    >Tăng trưởng kinh doanh, tiến bộ và thành công là Hong-Wei sở hữu đặc điểm
                    </span
                  >
                </div>
              </div>
            </div>
          </li> -->
          <!--<li class="pc__menu__dropdown">
            <router-link :to="{ name: 'Report' }">檢舉專區</router-link>
          </li>-->
        </ul>
      </div>
    </div>
    <div class="navbar__menu__dropdown"></div>
  </div>
</template>

<script>
import { apiProductMenu, apiInvestMenu } from "@/api/pages.js";

export default {
  name: "Header",
  data() {
    return {
      selecedtLan:'vn',
      //mobile
      about: false,
      product: false,
      productFreight: false,
      productPass: false,
      professional: false,
      service: false,
      resource: false,
      investFinance: false,
      investFinanceIncome: false,
      investShareholder: false,
      investCompany: false,
      investSocial: false,
      investContact: false,
      lan: false,
      location:'',
      ProductMenu:{},
      InvestMenu:{}
    };
  },
  mounted: async function () {
    this.location = location.href
    const res = await apiProductMenu();
    this.ProductMenu = res.data;
    console.log(this.ProductMenu)
    const res1 = await apiInvestMenu();
    this.InvestMenu = res1.data;
    console.log(this.InvestMenu)
    
    $(document).ready(function() {
      $('#menuToggle .submenu__list #invest_levelThree').hide()
      $('#menuToggle .submenu__list #product_levelThree').hide()
      $('#menuToggle .levelTwoButton').click(function(){
        const target = $(this).next()
        if(target.is(':visible')){
          target.slideUp()
        }else{
          target.slideDown()
        }
      })
    
      $('.submenu__list').hide()
      $('.mobile_menu_icon').click(function(){
        const target = $(this).next().next()
          if(target.is(':visible')){
          target.slideUp()
          }else{
          target.slideDown()
        }
      })
      $('.mobile_link').on('click',function(){
        $("input[type='checkbox']").prop("checked",false); 
      }) 
    })
  },
  computed:{
  },
  methods: {
    changeLanHandler() {
      switch(this.selecedtLan){
        case 'zh':
          console.log('進入zh')
          console.log(this.selecedtLan)
          window.location.href='https://www.volbin.com.tw';
        break;
        case 'en':
          console.log('進入en')
          console.log(this.selecedtLan)
          window.location.href='https://www.volbin.com/en';
        break;
        case 'ch':
          console.log('進入ch')
          console.log(this.selecedtLan)
          window.location.href='https://www.volbin.com.cn';
        break;
        case 'vn':
          console.log('進入vn')
          console.log(this.selecedtLan)
          window.location.href='https://www.volbin.com.vn';
        break;
      }
    },
    handleAnchor(){
      window.open('https://www.104.com.tw/jobs/main/','_blank')
    },
    handlePrdocutFristMenu(){
      console.log('')
    },
    selecedtLanHandle: function(){
      console.log(this.selecedtLan)
    },
    checkReload: function(){
    }
  },
};
</script>

<style lang="css" scoped>
.dropdown__pic span{ 
  line-height: 25px;
}
.pc__menu__dropdown__content h2{
  margin-right: 20px;
}
.pc__menu__dropdown__content ul li{
  /* margin-right: 20px; */
}
select {
  background: lightgray;
  color: black;
  margin: 10px 0px 10px 0px;
  width: 150px;
  padding: 5px 10px 5px 10px;
  position: relative;
  border-radius: 5px;
  border: 1px solid lightgray;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url('./../assets/pic/selectarrow.png') 94% / 9% no-repeat #EEE;
}
select:focus {
  outline: none;
}
.pc__menu__dropdown > .router-link-exact-active:after {
    content: "";
    display: block;
    border: 12px solid transparent;
    border-bottom-color: #009A44;
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -12px;
}
.pc__menu__dropdown > .router-link-exact-active{
  color: #009A44!important;
}
.mobile_menu_icon{
  width: 22px;
  float: right;
  height: 33px;
}
.pc__menu__dropdown > a,
.has-submenu {
  font-weight: 900;
}
</style>
